// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-besok-js": () => import("./../src/pages/besok.js" /* webpackChunkName: "component---src-pages-besok-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-oss-js": () => import("./../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-opplevelser-js": () => import("./../src/pages/opplevelser.js" /* webpackChunkName: "component---src-pages-opplevelser-js" */),
  "component---src-pages-personvern-js": () => import("./../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-templates-learning-resources-js": () => import("./../src/templates/LearningResources.js" /* webpackChunkName: "component---src-templates-learning-resources-js" */),
  "component---src-templates-subpage-event-js": () => import("./../src/templates/subpage-event.js" /* webpackChunkName: "component---src-templates-subpage-event-js" */),
  "component---src-templates-subpage-experience-js": () => import("./../src/templates/subpage-experience.js" /* webpackChunkName: "component---src-templates-subpage-experience-js" */),
  "component---src-templates-subpage-visit-js": () => import("./../src/templates/subpage-visit.js" /* webpackChunkName: "component---src-templates-subpage-visit-js" */)
}

